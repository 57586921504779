import React from "react";
import GatsbyLink from "../GatsbyLink";
import "./Pagination.scss";

export const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages = null,
  } = pageContext;
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
    pages.push(i + 1);
  }

  // dont display pagination if only one page
  if (numberOfPages === 1) {
    return null;
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {previousPagePath && (
          <GatsbyLink to={previousPagePath} className="prev" />
        )}
        <div className="pages">
          {pages.map((key, index) => (
            <GatsbyLink
              key={index}
              to={key !== 1 ? `${pathPrefix}page/${key}` : pathPrefix}
              activeClassName="active"
            >
              {key}
            </GatsbyLink>
          ))}
        </div>
        {nextPagePath && <GatsbyLink to={nextPagePath} className="next" />}
      </div>
    </nav>
  );
};
